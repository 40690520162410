
import { defineComponent, ref } from "vue";
import * as LandingServices from "../api/helpers/Landing";
import Spinner from "../components/Spinner.vue";
import * as FacebookServices from "../api/helpers/Facebook";
import "particles.js";

export default defineComponent({
  name: "Results",
  components: {
    Spinner,
  },
  setup(props) {
    var loading = ref(false);
    var html = ref();
    const LANDING_PATH = "/landing/";
    const landingId = window.location.pathname.replace(LANDING_PATH, "");
    return {
      loading,
      landingId,
      html
    };
  },
  mounted() {
    FacebookServices.pageView();
    this.initParticles();
    this.loading = true;
    document.title = "Prestamos Personales | " + process.env.VUE_APP_TITLE;
    window.scrollTo(0, 0);
    const PARAMS = "params";
    if (!this.landingId) this.$router.push("../");
    const queryString = window.location.search;
    if (queryString) {
      const urlParams = new URLSearchParams(queryString);
      var params: any;
      for (const entry of urlParams.entries()) {
        params[entry[0]] = entry[1];
      }
      sessionStorage.setItem(PARAMS, JSON.stringify(params));
    }
    this.find();
  },
  methods: {
    find() {
      LandingServices.find(this.landingId, process.env.VUE_APP_SYSTEM_ID).then(
        (res) => {
          const { data } = res.data;
          if (!data.length) this.$router.push("../");
          this.html = data[0].html;
          this.loading = false;
        },
        (err) => {
          console.log(err);
          this.$router.push("../");
        }
      );
    },
    initParticles() {
      window.particlesJS("particles-js", {
        particles: {
          number: {
            value: 250,
            density: {
              enable: true,
              value_area: 700,
            },
          },
          color: {
            value: "#ffffff",
          },
          shape: {
            type: "circle",
            stroke: {
              width: 0,
              color: "#000000",
            },
            polygon: {
              nb_sides: 10,
            },
          },
          opacity: {
            value: 0.75,
            random: true,
            anim: {
              enable: true,
              speed: 0,
              opacity_min: 0.2,
              sync: true,
            },
          },
          size: {
            value: 3,
            random: true,
            anim: {
              enable: true,
              speed: 0,
              size_min: 50,
              sync: false,
            },
          },
          line_linked: {
            enable: true,
            distance: 0,
            color: "#ffffff",
            opacity: 0.4,
            width: 1,
          },
          move: {
            enable: true,
            speed: 2,
            direction: "none",
            random: true,
            straight: false,
            out_mode: "out",
            bounce: false,
            attract: {
              enable: false,
              rotateX: 600,
              rotateY: 1200,
            },
          },
        },
        interactivity: {
          detect_on: "canvas",
          events: {
            onhover: {
              enable: false,
              mode: "grab",
            },
            onclick: {
              enable: true,
              mode: "push",
            },
            resize: true,
          },
          modes: {
            grab: {
              distance: 140,
              line_linked: {
                opacity: 1,
              },
            },
            bubble: {
              distance: 400,
              size: 40,
              duration: 2,
              opacity: 8,
              speed: 3,
            },
            repulse: {
              distance: 200,
              duration: 0.4,
            },
            push: {
              particles_nb: 4,
            },
            remove: {
              particles_nb: 2,
            },
          },
        },
        retina_detect: true,
      });
    },
  },
});
